import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div className="py-3 footer text-center px-3">
      <span className="text-light">
        Copyright © IntermediaMarketingGroup All rights reserved | Designed
        by&nbsp;
        <a
          href="https://ciphersavvy.com"
          target="_blank"
          className="text-light"
        >
          Cipher Savvy
        </a>
      </span>
      <br className="d-md-none" />
      <a
        href="https://www.facebook.com/Intermedia-Marketing-Group-100942205067323"
        target="_blank"
      >
        <FontAwesomeIcon
          icon={faFacebookSquare}
          style={{ fontSize: 28 }}
          className="text-light float-md-right mx-3 mt-3 mt-md-0"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/intermedia-marketing-group-llc"
        target="_blank"
      >
        <FontAwesomeIcon
          icon={faLinkedin}
          style={{ fontSize: 28 }}
          className="text-light float-md-right mt-3 mt-md-0"
        />
      </a>
      <div className="row">
        <div className="d-flex">
          <p className="text-bold mr-4">Useful Links:</p>
          <Link className="mr-3" to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className="mr-3" to="/terms-and-conditions">
            Terms and Conditions
          </Link>
          <Link className="mr-3" to="/disclaimer">
            Disclaimer
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
