import React, { useState } from "react"
import { Link as Redirect } from "gatsby"
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Logo from "../../images/logo.png"

const Header = props => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Navbar color="light" light expand="md" fixed="top" className="wrapper nav-wrapper">
        <Redirect to="/" className="navbar-brand">
          <img src={Logo} alt="logo" />
        </Redirect>
        
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <AnchorLink
                to="/#home"
                title="Home"
                stripHash
                className="nav-link"
              />
            </NavItem>
            <NavItem>
              <AnchorLink
                to="/#features"
                title="How We Do It"
                stripHash
                className="nav-link"
              />
            </NavItem>
            <NavItem>
              <AnchorLink
                to="/#about-us"
                title="About"
                stripHash
                className="nav-link"
              />
            </NavItem>
            <NavItem>
              <AnchorLink
                to="/#services"
                title="What We Do"
                stripHash
                className="nav-link"
              />
            </NavItem>
            <NavItem>
              <AnchorLink
                to="/#consulting"
                title="Consulting"
                stripHash
                className="nav-link"
              />
            </NavItem>
            <NavItem>
              <AnchorLink
                to="/#websites"
                title="Websites"
                stripHash
                className="nav-link"
              />
            </NavItem>
            <NavItem>
              <AnchorLink
                to="/#contact-us"
                title="Contact Us"
                stripHash
                className="nav-link"
              />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Header
